// @ts-strict-ignore
import { schema } from './schema'
import DatepickerInput from '@components/Datepicker'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { useNotifications } from '@components/NotificationsProvider'
import { LinariaHeading } from '@components/gassan-ui/Typography/LinariaHeading'
import { Box, Button, Field, Grid, Heading, Radio, Span } from '@gassan-ui'
import {
  ContactPreference,
  Gender,
  LeadStore,
  LeadType,
  useSubmitContactFormMutation,
} from '@generated'
import { capitalizeWords } from '@lib/capitalize-words'
import { genericPushToTrackingLayer } from '@lib/data-layer-social-ads/generic-push-to-tracking-layer'
import { useFinishedButtonState } from '@lib/hooks/useFinishedButtonState'
import { Form, Formik } from 'formik'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type StoreTranslation = { title: string; value: string }

type Props = {
  subject?: string
  leadStore?: LeadStore
  onSubmitSuccess?: (values: typeof initialValues) => void
}

const initialValues = {
  type: LeadType.Appointment,
  gender: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  contactPreference: ContactPreference.Email,
  store: '',
  preferredDate: '',
  message: '',
  preOwnedContactFormAboutWatch: '',
  agree: false,
  subscribe: false,
}

const ContactForm: FC<Props> = ({ subject, onSubmitSuccess, leadStore }) => {
  const { t } = useTranslation(['notifications', 'forms', 'navigation', 'contact'])
  const notifications = useNotifications()
  const [result, submitContactForm] = useSubmitContactFormMutation()
  const isLoading = result.fetching
  const isFinished = useFinishedButtonState(isLoading)
  const status = isLoading ? 'loading' : isFinished ? 'finished' : undefined

  const stores: StoreTranslation[] = t('stores', { returnObjects: true, ns: 'contact' })

  return (
    <Formik<typeof initialValues>
      validationSchema={schema}
      initialValues={initialValues}
      validateOnBlur
      validateOnChange
      onSubmit={async (values, { resetForm }) => {
        const {
          type,
          gender,
          firstName,
          lastName,
          email,
          phone,
          contactPreference,
          message,
          subscribe,
          preferredDate,
          preOwnedContactFormAboutWatch,
          store,
        } = values
        // find the title of the store if the value is set, otherwise undefined
        const preferredStore = store ? stores.find((s) => s.value === store)?.title : undefined

        const { data, error } = await submitContactForm({
          input: {
            type,
            gender: gender as Gender,
            firstName: capitalizeWords(firstName),
            lastName: capitalizeWords(lastName),
            email,
            phone,
            message: preOwnedContactFormAboutWatch
              ? `About: ${preOwnedContactFormAboutWatch}. Message: ${message}`
              : message,
            subject,
            leadStore,
            preferredDate,
            preferredStore,
            referrer: window.location.href,
            referrerTitle: document.title,
            contactPreference: contactPreference as ContactPreference,
          },
          newsletterSignup: subscribe,
        })
        if (error) {
          if (process.env.NODE_ENV === 'development') {
            console.error(error)
          }
          notifications.addNotification({
            variant: 'error',
            label: t('refreshAndTryAgain', { ns: 'notifications' }),
          })
        }

        if (data) {
          // Transform date from 20-05-2020 -> 20 May 2020
          const dateSplit = values.preferredDate.split('-')
          dateSplit[1] = t(`months.${parseInt(dateSplit[1], 10) - 1}`, { ns: 'forms' })

          if (['staging', 'production'].includes(process.env.NEXT_PUBLIC_STAGE)) {
            const isAppointment = values.type === LeadType.Appointment
            genericPushToTrackingLayer(
              isAppointment ? 'appointment_form_success' : 'askquestion_form_success',
            )
          }

          notifications.addNotification(
            values.type === LeadType.Appointment
              ? {
                  variant: 'confirmation',
                  description: t('requestSent', { ns: 'notifications' }),
                  label: dateSplit.join(' '),
                  sublabel: t('shopAppointment', { ns: 'notifications' }),
                }
              : {
                  variant: 'confirmation',
                  description: t('requestSent', { ns: 'notifications' }),
                  label: t('weWillContactYou', { ns: 'notifications' }),
                },
          )

          if (subscribe) {
            genericPushToTrackingLayer('nieuwsbrief', 'inschrijving')
          }

          resetForm()
          onSubmitSuccess?.(values)
        }
      }}
    >
      {({ values, setFieldValue, errors, touched, setTouched }) => (
        <Form data-contact-form>
          <Grid gridTemplateColumns="1fr" gridGap={[0, '0.5rem']}>
            <Box
              mb="4"
              style={{ gap: '4px' }}
              display="flex"
              flexDirection={{ _: 'column', large: 'row' }}
            >
              <Radio
                name="type"
                value={LeadType.Appointment}
                id="make-appointment"
                label={t('labels.makeAppointment', { ns: 'forms' })}
                onChange={() => setFieldValue('type', LeadType.Appointment)}
                checked={values.type === LeadType.Appointment}
                mb="0"
              />
              <Radio
                name="type"
                value={LeadType.Question}
                id="ask-question"
                onChange={() => setFieldValue('type', LeadType.Question)}
                label={t('labels.askQuestion', { ns: 'forms' })}
                checked={values.type === LeadType.Question}
                mb="0"
              />
            </Box>

            <Heading variant="h5" mt="0" mb="2">
              {t('sectionContact', { ns: 'contact' })}
            </Heading>
            <Field name="gender" label={t('labels.salutation', { ns: 'forms' })} field="select">
              <option value="" disabled>
                {t('labels.salutation', { ns: 'forms' })}
              </option>
              <option value={Gender.Male}>{t('labels.mr', { ns: 'forms' })}</option>
              <option value={Gender.Female}>{t('labels.mrs', { ns: 'forms' })}</option>
              <option value={Gender.Other}>{t('labels.other', { ns: 'forms' })}</option>
            </Field>
            <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gridColumnGap="1.5rem">
              <Field
                name="firstName"
                label={t('labels.firstName', { ns: 'forms' })}
                field="input"
              />
              <Field name="lastName" label={t('labels.lastName', { ns: 'forms' })} field="input" />
            </Grid>
            <Field name="email" label={t('labels.email', { ns: 'forms' })} field="input" />
            <Field name="phone" label={t('labels.phone', { ns: 'forms' })} field="input" />
            <Box mt="3" mb="4">
              <LinariaHeading variant="h5" as="h5" mb="2">
                {t('labels.contactPreference', { ns: 'forms' })}
              </LinariaHeading>
              <Box
                style={{ gap: '4px' }}
                display="flex"
                flexDirection={{ _: 'column', large: 'row' }}
              >
                <Radio
                  name="contactPreference"
                  value={ContactPreference.Email}
                  id="contact-preference-email"
                  label={t('labels.contactPreferenceEmail', { ns: 'forms' })}
                  onChange={() => setFieldValue('contactPreference', ContactPreference.Email)}
                  checked={values.contactPreference === ContactPreference.Email}
                  mb="0"
                />
                <Radio
                  name="contactPreference"
                  value={ContactPreference.Phone}
                  id="contact-preference-phone"
                  onChange={() => setFieldValue('contactPreference', ContactPreference.Phone)}
                  label={t('labels.contactPreferencePhone', { ns: 'forms' })}
                  checked={values.contactPreference === ContactPreference.Phone}
                  mb="0"
                />
              </Box>
            </Box>
            {values.type === LeadType.Appointment && (
              <>
                <Heading variant="h5" mt="3" mb="2">
                  {t('sectionWhereWhen', { ns: 'contact' })}
                </Heading>
                <Field name="store" label={t('labels.store', { ns: 'forms' })} field="select">
                  <option value="" disabled>
                    {t('labels.store', { ns: 'forms' })}
                  </option>
                  {stores.map((store) => (
                    <option value={store.value} key={store.value}>
                      {store.title}
                    </option>
                  ))}
                </Field>
                <DatepickerInput
                  name="preferredDate"
                  value={values.preferredDate}
                  onChange={(date) => setFieldValue('preferredDate', date)}
                  onBlur={() => setTouched({ preferredDate: true })}
                  errorMsg={
                    touched.preferredDate && errors.preferredDate
                      ? t('errors.date', { ns: 'forms' })
                      : undefined
                  }
                />
              </>
            )}

            <Heading variant="h5" mt="3" mb="2">
              {t('sectionHowCanWeHelp', { ns: 'contact' })}
            </Heading>
            <Field name="message" field="textarea" label={t('labels.message', { ns: 'forms' })} />
            {leadStore === LeadStore.PreOwned && (
              <Field
                name="preOwnedContactFormAboutWatch"
                label={t('labels.preOwnedContactFormAboutWatch', { ns: 'forms' })}
                field="input"
              />
            )}
            <Box mb={{ _: '1rem', small: '0', large: '1rem' }}>
              <Field
                name="agree"
                field="checkbox"
                label={
                  <Span>
                    {t('labels.privacyNotice', { ns: 'forms' })}
                    <LocalizedLink href={t('privacyPolicy', { ns: 'navigation' })}>
                      <a
                        style={{ fontWeight: 'bold' }}
                        href={t('privacyPolicy', { ns: 'navigation' })}
                      >
                        {t('labels.privacyPolicy', { ns: 'forms' })}
                      </a>
                    </LocalizedLink>
                  </Span>
                }
              />
              <Field
                name="subscribe"
                label={t('labels.subscribe', { ns: 'forms' })}
                field="checkbox"
              />
            </Box>
            <Button variant="dark" type="submit" status={status}>
              {t('submits.sendMessage', { ns: 'forms' })}
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
